<template>
  <div style="padding-bottom: 4.5%">
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-row class="no-gutters">
        <b-col>
          <h1 class="mr-sm-4 header-tablepage">RIGHT REQUEST DETAIL : {{ id }}</h1>
        </b-col>
      </b-row>
      <div class="title-panel mt-3">General Detail</div>
      <b-container class="no-gutters bg-white">
        <div class="p-3">
          <b-row>
            <b-col sm="3"><p class="mb-1 p-font">Request Type</p>
              <span>{{ detail.request_name }}</span>
            </b-col>
            <b-col sm="3"
              ><p class="mb-1 p-font">Creation Date</p>
              {{ new Date(detail.created_time) | moment($formatDate) }}
              {{ new Date(detail.created_time) | moment($formatTime) }}
            </b-col>
            <b-col sm="3">
              <p class="mb-1 p-font">Status</p>
              <span
                :class="
                  detail.request_status_id == 1
                    ? ''
                    : detail.request_status_id == 2
                    ? 'text-success'
                    : 'text-danger'
                "
              >
                {{
                  detail.request_status_id == 1
                    ? "Open"
                    : detail.request_status_id == 2
                    ? "Success"
                    : "Cancel"
                }}
              </span>
            </b-col>
            <b-col sm="3">
              <p class="mb-1 p-font">
                {{
                  detail.request_status_id == 1
                    ? "Opening Date"
                    : "Date of Completion"
                }}
              </p>
              {{ new Date(detail.updated_time) | moment($formatDate) }}
              {{ new Date(detail.updated_time) | moment($formatTime) }}
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col sm="3">
              <p class="mb-1 p-font">Admin Name</p>
              <span v-if="detail.admin_name">{{ detail.admin_name }}</span
              ><span v-else>-</span>
            </b-col>
            <b-col sm="3" v-if="detail.request_status_id == 3">
              <p class="mb-1 p-font">Additional Notes</p>
              {{ detail.note }}
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col sm="3">
              <p class="mb-1 p-font">Member ID</p>
              <b-button variant="link" class="px-0 py-0">
                <router-link :to="'/report/customer/' + detail.user_guid">
                  <u>{{ detail.member_id }}</u>
                </router-link>
              </b-button>
            </b-col>
            <b-col sm="3">
              <p class="mb-1 p-font">Name-Surname</p>
              <span>{{ detail.first_name_th }} {{ detail.last_name_th }}</span>
              </b-col>
            <b-col sm="3"
              ><p class="mb-1 p-font">Date/Month/Year of Birth</p>
              <span>{{
                detail.birthday == "****"
                  ? "****"
                  : new Date(detail.birthday) | moment($formatDate)
              }}</span></b-col
            >
            <b-col sm="3"
              ><p class="mb-1 p-font">Gender</p>
              <span>{{ detail.gender }}</span></b-col
            >
          </b-row>
          <b-row class="mt-4">
            <b-col sm="3"
              ><p class="mb-1 p-font">Tel.</p>
              <span>{{ detail.telephone }}</span></b-col
            >
            <b-col sm="3"
              ><p class="mb-1 p-font">Email</p>
              <span>{{ detail.email }}</span></b-col
            >
            <!-- <b-col sm="3"
              ><p class="mb-1 p-font">วันที่อัพเดทล่าสุด</p>
              <span
                >{{ new Date(detail.updateDate) | moment($formatDate) }}
                {{ new Date(detail.updateDate) | moment($formatTime) }}</span
              ></b-col
            > -->
            <b-col sm="3"
              ><p class="mb-1 p-font">Status Consent</p>
              <span>{{ detail.consent_status }}</span></b-col
            >
          </b-row>
          <b-row class="mt-3">
            <b-col sm="12" class="d-flex justify-content-center">
              <b-button
                class="confirm-btn"
                :disabled="detail.request_status_id != 1"
                @click="modalMessageShow = true"
              >
                Cancel Request
              </b-button>
              <b-button
                class="ml-2 confirm-btn"
                :disabled="detail.request_status_id != 1"
                @click="confirmRequest(1)"
              >
                Confirm Request
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="title-panel mt-3">Usage History</div>
      <b-container class="no-gutters bg-white">
        <div class="py-3">
          <b-row>
            <b-col>
              <b-table
                striped
                hover
                responsive
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
              >
                <template v-slot:cell(first_name_th)="data">
                  <div class="text-left">
                    <div v-if="data.item.first_name_th">
                      <p class="mb-1">
                        {{ data.item.first_name_th }} {{ data.item.last_name_th }}
                      </p>
                      <span
                        ><b-button variant="link" class="px-0 py-0">
                          <router-link
                            :to="'/report/customer/' + data.item.user_guid"
                          >
                            <u>{{ data.item.member_id }}</u>
                          </router-link>
                        </b-button></span
                      >
                    </div>
                    <div v-else>-</div>
                  </div>
                </template>
                <template v-slot:cell(dateTime)="data">
                  <div v-if="data.item.dateTime">
                    {{ new Date(data.item.dateTime) | moment($formatDateNew) }}
                    {{ new Date(data.item.dateTime) | moment($formatTime) }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:cell(request_status_id)="data">
                  <div
                    :class="
                      data.item.request_status_id == 1
                        ? ''
                        : data.item.request_status_id == 2
                        ? 'text-success'
                        : 'text-danger'
                    "
                  >
                    {{
                      data.item.request_status_id == 1
                        ? "Open"
                        : data.item.request_status_id == 2
                        ? "Success"
                        : "Cancel"
                    }}
                  </div>
                </template>
                <template v-slot:cell(admin_name)="data">
                  <div v-if="data.item.admin_name">
                    {{ data.item.admin_name }}
                  </div>
                  <div v-else>-</div>
                </template>
                  <!-- <template v-slot:cell(manage)="data">
                    <b-button
                      variant="link"
                      class="px-1 py-0"
                      @click="selectHistory"
                    >
                      <router-link
                        :to="`/report/customer/${data.item.user_guid}`"
                      >
                        <u>Detail</u>
                      </router-link>
                    </b-button>
                  </template> -->
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="form-inline justify-content-start">
              <p class="mb-0 p-gray">
                Showing {{ rows > 0 ? showing + (filter.page - 1) : 0 }} to
                {{ showingTo > rows ? rows : showingTo }} of
                {{ rows }} entires
              </p>
            </b-col>
            <b-col cols="12" md="6" class="form-inline justify-content-end">
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.take"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="m-md-0"
                @change="pagination"
              ></b-pagination>
              <b-form-select
                class="ml-1"
                v-model="filter.take"
                @change="handleChangeTake"
                :options="pageOptions"
              ></b-form-select>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <div class="footer-action">
      <b-col class="text-left">
        <b-button @click.prevent="$router.go(-1)" class="btn-cancel btn-width">Back</b-button>
      </b-col>
    </div>
    <b-modal
      v-model="modalMessageShow"
      title="Product List"
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      header-class="bg-purple1"
    >
      <template #modal-header>
        <div class="w-100">
          <strong class="title-modal white-color">Notice of Cancellation of Request</strong>
        </div>
      </template>
      <div>
        <InputTextArea
          textFloat="Additional Notes"
          type="text"
          v-model="detail.note"
          class="mt-0 custom-input"
          placeholder="Additional Notes"
          rows="7"
          name="message"
        />
        <b-row>
          <b-col sm="6">
            <b-button
              class="modal-btn cancel-btn"
              @click="modalMessageShow = false"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col sm="6" class="d-flex justify-content-end">
            <b-button class="modal-btn submit-btn" @click="submitMessageModel">
              Confirm Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputTextArea from "@/components/inputs/InputTextArea";
import ModalAlertError from "@/components/modal/ModalAlertError";
export default {
  name: "AllRightRequestDetail",
  components: {
    OtherLoading,
    InputTextArea,
    ModalAlertError,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
      rows: 0,
      showing: 1,
      showingTo: 10,
      form: {
        message: "",
      },
      detail: {
        member_id: "",
        request_name: "",
        created_time: "",
        updated_time: "",
        request_status_id: 1,
        admin_name: "",
        note: "",
        first_name_th: "",
        last_name_th: "",
        birthday: "",
        gender: "",
        telephone: "",
        email: "",
        updateDate: "",
        consent: "",
      },
      filter: {
        member_id: "",
        page: 1,
        take: 10,
      },
      items: [],
      fields: [
        {
          key: "request_id",
          label: "Request ID",
          class: "px-0 text-nowrap",
          tdClass: "table-list",
        },
        {
          key: "first_name_th",
          label: "Name-Surname",
          class: "px-0 text-nowrap",
        },
        {
          key: "request_name",
          label: "Request Type",
          class: "px-0 text-nowrap",
        },
        {
          key: "created_time",
          label: "Create Date",
          class: "px-0 text-nowrap",
        },
        {
          key: "request_status_id",
          label: "Status",
          class: "px-0 text-nowrap",
        },
        {
          key: "admin_name",
          label: "Admin Name",
          class: "px-0 text-nowrap",
        },
        {
          key: "manage",
          label: "",
          class: "px-0 text-nowrap",
        },
      ],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      modalMessage: "",
      modalMessageShow: false,
      isBusy: false,
      isLoading: true,
    };
  },
  async created() {
    await this.getDetail();
    await this.getHistory();
    this.isLoading = false;
  },
  methods: {
    async getDetail() {
      await this.$store.dispatch("getAllRightRequestDetail", this.id);
      let data = this.$store.state.allRightRequest.allRightRequestDetail;
      if (data.result == 1) {
        this.detail = data.detail;
      }
    },
    async getHistory() {
      this.isBusy = true;
      this.filter.member_id = this.detail.member_id;
      await this.$store.dispatch("getAllRightRequestHistory", this.filter);
      let data = this.$store.state.allRightRequest.allRightRequestHistory;
      if (data.result == 1) {
        this.items = data.detail.data;
        this.rows = data.detail.total_count;
        this.isBusy = false;
      }
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
      }
      this.getHistory();
    },
    handleChangeTake(take) {
      this.filter.page = 1;
      this.filter.take = take;
      this.showingTo = this.filter.take;
      this.getHistory();
    },
    async selectHistory() {
      this.isLoading = true;
      this.id = parseInt(this.$route.params.id);
      await this.getDetail();
      await this.getHistory();
      this.isLoading = false;
    },
    submitMessageModel() {
      this.modalMessageShow = false;
      this.confirmRequest(0);
    },
    async confirmRequest(status) {
      this.isLoading = true;
      let body = {
        user_guid: this.detail.user_guid,
        admin_user_guid: this.$cookies.get("back_office_admin_user_guid"),
        request_id: this.detail.request_id,
        request_type_id: this.detail.request_type_id,
        confirm: status,
        note: this.detail.note,
      };
      await this.$store.dispatch("confirmRequest", body);
      let data = this.$store.state.allRightRequest.resConfirmRequest;
      if (data.result == 1) {
        this.isLoading = false;
        if (status == 1) {
          this.detail.request_status_id = 2;
        } else {
          this.detail.request_status_id = 3;
        }
        this.filter.page = 1;
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
        this.getDetail();
        this.getHistory();
      } else {
        this.isLoading = false;
        this.$refs.modalAlertError.show();
        this.modalMessage = data.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.p-font {
  color: #212529;
  font-size: 14px;
  font-weight: bold;
}
.modal-btn {
  width: 140px;
}
.confirm-btn {
  width: 200px;
  color: #fff;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 3px;
}
@media (min-width: 350px) and (max-width: 991px) {
  .footer-action {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.footer-action {
  margin-left: var(--margin-spacing);
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  display: flex;
  flex-direction: row;
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}
// .confirm-btn:hover {
//   color: var(--primary-color);
//   background-color: #fff;
//   border: 1px solid var(--primary-color);
// }
.confirm-btn:disabled {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}
// .confirm-btn:hover:disabled {
//   color: #fff;
//   background-color: #bababa;
//   border: 1px solid #bababa;
// }
</style>
